import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, List, Table } from 'semantic-ui-react';

const FilecheckModal = props => {
  const expected =
    props.files && props.files.expected ? props.files.expected.files : {};
  const expectedCount =
    (expected.documents ? expected.documents.length : 0) +
    (expected.icons ? expected.icons.length : 0) +
    (expected.meshes ? expected.meshes.length : 0);
  const missing = props.files && props.files.missing ? props.files.missing : [];
  return (
    <Modal open={props.show} closeOnEscape={false} closeOnRootNodeClick={false}>
      <Modal.Header>Files</Modal.Header>
      <Modal.Content>
        <List divided relaxed>
          <List.Item key="Expected">
            <List.Content>
              <List.Header>Expected</List.Header>
              <List.Description>
                <Table basic="very" compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell collapsing>
                        {expectedCount}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Documents</Table.Cell>
                      <Table.Cell>
                        {expected.documents ? expected.documents.length : 0}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Icons</Table.Cell>
                      <Table.Cell>
                        {expected.icons ? expected.icons.length : 0}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Meshes</Table.Cell>
                      <Table.Cell>
                        {expected.meshes ? expected.meshes.length : 0}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item key="Found">
            <List.Content>
              <List.Header>Found</List.Header>
              <List.Description>
                <Table basic="very" compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell collapsing>
                        {props.files && props.files.found
                          ? props.files.found.length
                          : 0}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                </Table>
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item key="Missing">
            <List.Content>
              <List.Header>Missing</List.Header>
              <List.Description>
                <Table basic="very" compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell collapsing>
                        {missing.length ? `${missing.length} ❌` : 'None ✅'}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {missing.map(item => (
                      <Table.Row>
                        <Table.Cell>{item}</Table.Cell>
                        <Table.Cell> </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          labelPosition="right"
          icon="checkmark"
          content="Close"
          onClick={() => props.hideModal()}
        />
      </Modal.Actions>
    </Modal>
  );
};

FilecheckModal.propTypes = {
  show: PropTypes.bool,
  stepLabel: PropTypes.string,
  percent: PropTypes.number,
  hideModal: PropTypes.func,
};

export default FilecheckModal;
