import React from 'react';

export const getLocalDate = row => {
  const utc = new Date(row.date).toUTCString();
  const local = `${new Date(row.date).toLocaleDateString('fr-FR')} ${new Date(
    row.date,
  ).toLocaleTimeString('fr-FR', {
    timeZone: 'Europe/Paris',
  })}`;
  return {
    utc,
    local,
  };
};

export const RequestDetails = props => {
  if (!props.requestData) return <div>No data</div>;
  const r = props.requestData;
  return (
    <div>
      <br />
      <h4>Request {r.id}</h4>
      <input
        type="button"
        onClick={props.onToggleRawFields}
        value={props.showRawFields ? 'Hide raw fields' : 'show raw fields'}
      />
      {props.showRawFields ? (
        <fieldset>
          <ul>
            {Object.keys(r).map(field => (
              <li>
                {field} : {r[field]}
              </li>
            ))}
          </ul>
        </fieldset>
      ) : null}
      <fieldset>
        <legend>Details</legend>
        Status : {r.status}
        <br />
        Quantity : {r.quantity}
        <br />
        Action : {r.priority_name} (
        {parseInt(r.notificationFlag, 10) === 1 ? 'critical' : 'routine'})
        <br />
        UTC date : {getLocalDate(r).utc}
        <br />
        French date : {getLocalDate(r).local}
        <br />
        Created with time offset {r.timeOffset}
        <br />
        nonce : {r.nonce}
      </fieldset>
      <fieldset>
        <legend>User</legend>
        <b>{r.user_name}</b> (id : {r.user_id} ; login : {r.user_login})<br />
        Customer : {r.customer_name}{' '}
        <p className="customer-badge" data-oaci={r.customer_oaci}>
          {r.customer_oaci}
        </p>
      </fieldset>
      <fieldset>
        <legend>Aircraft</legend>
        <b>{r.aircraft_registration}</b> ({r.aircraft_type}) operated by{' '}
        {r.aircraft_operator}
      </fieldset>
      <fieldset>
        <legend>Position</legend>
        <b>
          {r.lopaequipment_row}
          {r.itemLocationPax}
        </b>
        <br />
        {r.family_name} (class {r.lopaequipment_class}), area{' '}
        {r.lopaequipment_area}
        <br />
        Equipment position : {r.lopaequipment_position} ({r.equipment_side})
        <br />
        Equipment description : {r.equipment_description}
        <br />
        Lopa : {r.lopa_description}
      </fieldset>
      <fieldset>
        <legend>Item</legend>
        P/N : <b>{r.itemPartNumber}</b>
        <br />
        Figure {r.figureDoc} Item {r.itemDoc}
        <br />
        {r.itemDescriptionDoc}
        <br />
        SubAssembly : {r.subassembly_name}, {r.layer_description} layer
        <br />
        Figure is{' '}
        {parseInt(r.figure_active, 10) === 1 ? 'active' : <b>inactive</b>}
      </fieldset>
    </div>
  );
};
