import * as types from '../config/types';
import * as network from '../config/network';

const initialState = {
  api: network.API_URL,
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.API_SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case types.API_SET_API:
      return {
        ...state,
        api: action.api,
      };
    default:
      return state;
  }
};
