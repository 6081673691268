import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import 'babylonjs-serializers';
import { Scene } from 'react-babylonjs';
import { BO_URL } from '../../config/network';

const getTemplateComposition = meshes => {
  let text = '';
  let currentIndex = 0;
  meshes.forEach((mesh, index) => {
    const totalIndices = mesh.getIndices().length;
    text = text.concat(`${mesh.name};${currentIndex};${totalIndices}\n`);
    currentIndex += totalIndices;
  });
  return text;
};

const degreesToRadian = degree => degree * Math.PI / 180.0;

// this is the kept data for merging
// all other data is currently ignored
const vertexDataKinds = [
  BABYLON.VertexBuffer.PositionKind,
  BABYLON.VertexBuffer.NormalKind,
];

class MeshBabylon extends Component {
  constructor(props) {
    super(props);

    this.onSceneMount = this.onSceneMount.bind(this);
    this.onMeshPicked = this.onMeshPicked.bind(this);
    this.buildScene = this.buildScene.bind(this);
    this.buildSceneAsync = this.buildSceneAsync.bind(this);

    this.state = {
      selectedLayer: null,
    };

    this.scene = null;
    this.light = null;
    this.camera = null;
    this.assetsManager = null;
    this.meshes = [];
  }

  componentWillReceiveProps(nextProps) {
    if (this.scene && nextProps.config !== this.props.config) {
      this.buildScene(nextProps.config);
    }
  }

  buildSceneAsync(config) {
    return new Promise(resolve => {
      this.buildScene(config, result => {
        resolve(result);
      });
    });
  }

  buildScene(config, headlessCallback) {
    let meshes = headlessCallback ? [] : this.meshes;
    meshes.map(mesh => mesh.dispose());
    if (meshes.length !== 0) meshes = [];
    config.models.forEach((model, index) => {
      const file = model.file.replace('.obj', '.babylon');
      const task = this.assetsManager.addMeshTask(file, '', BO_URL, file);
      task.onSuccess = t => {
        const mesh = new BABYLON.Mesh(file, this.scene);
        vertexDataKinds.map(kind => {
          const data = t.loadedMeshes[0].getVerticesData(kind, true, true);
          mesh.setVerticesData(kind, data);
        });
        const indices = t.loadedMeshes[0].getIndices(true);
        mesh.setIndices(indices);
        mesh.scaling = t.loadedMeshes[0].scaling;
        t.loadedMeshes[0].setEnabled(false);
        mesh.bakeTransformIntoVertices(BABYLON.Matrix.Scaling(1, -1, -1));
        mesh.position = new BABYLON.Vector3(
          parseFloat(model.position.x),
          parseFloat(model.position.z),
          -parseFloat(model.position.y),
        );
        mesh.rotation = new BABYLON.Vector3(
          degreesToRadian(parseFloat(model.rotation.x)),
          degreesToRadian(parseFloat(model.rotation.z)),
          degreesToRadian(parseFloat(model.rotation.y)),
        );
        meshes[index] = mesh;
      };
      task.onError = (task, message, exception) => {
        console.error(message, exception);
      };
    });
    this.assetsManager.onFinish = () => {
      if (headlessCallback) {
        const templateComposition = getTemplateComposition(meshes);
        const newMesh = BABYLON.Mesh.MergeMeshes(
          meshes,
          true,
          false,
          null,
          true,
        );
        newMesh.name = `${config.equipmentRepId}-${config.layer}`;
        newMesh.subMeshes.forEach((current, index) => {
          current.materialIndex = index;
        });
        const model = BABYLON.SceneSerializer.SerializeMesh(
          newMesh,
          false,
          false,
        );
        const modelText = JSON.stringify(model);
        headlessCallback({
          config,
          template: modelText,
          name: newMesh.name,
          layer: config.layer,
          templateComposition,
        });
      } else {
        this.meshes = meshes;
        const rootObject = new BABYLON.TransformNode('root');
        rootObject.rotation.x = Math.PI / 2.0;
        meshes.forEach(mesh => {
          mesh.parent = rootObject;
        });
      }
    };
    this.assetsManager.load();
  }

  onMeshPicked(mesh, scene) {}

  onSceneMount(e) {
    const { canvas, scene, engine } = e;

    this.scene = scene;
    scene.clearColor = BABYLON.Color3.White();
    if (!this.props.headless) {
      this.light = new BABYLON.HemisphericLight(
        'HemiLight',
        new BABYLON.Vector3(-1, 1, 0),
        scene,
      );
    }

    this.camera = new BABYLON.ArcRotateCamera(
      'Camera',
      Math.PI / 2 + Math.PI / 4,
      Math.PI / 4,
      100,
      new BABYLON.Vector3(0, 10, 0),
      scene,
    );
    this.camera.panningSensibility = 100;
    this.camera.attachControl(canvas);
    this.assetsManager = new BABYLON.AssetsManager(scene);
    if (this.props.config) this.buildScene(this.props.config);

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
      }
    });
  }

  render() {
    if (this.props.headless)
      return (
        <Scene
          onSceneMount={this.onSceneMount}
          width="1"
          height="1"
          adaptToDeviceRatio
        />
      );
    return (
      <Scene
        id="babylonView"
        onSceneMount={this.onSceneMount}
        onMeshPicked={this.onMeshPicked}
        visible="true"
        adaptToDeviceRatio
      />
    );
  }
}

MeshBabylon.propTypes = {
  config: PropTypes.object,
  headless: PropTypes.bool,
};

export default MeshBabylon;
