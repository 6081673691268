import * as types from '../config/types';
import * as network from '../config/network';
import { get, getFree, postFree, upload } from '../lib/api';

import hash from 'object-hash';

export const loadMeshes = token => (dispatch, getState) => {
  let layers;
  let rawMeshes;
  const api = getState().API.api;
  get(api, network.API_MESH_LAYERS, token)
    .then(loadedLayers => {
      layers = loadedLayers;
      return get(api, network.API_MESH_LIST, token);
    })
    .then(loadedMeshes => {
      rawMeshes = loadedMeshes;
      return getFree(network.BO_SERVER_URL, token);
    })
    .then(configs => {
      const meshes = [];
      rawMeshes.forEach(mesh => {
        const config = [];
        mesh.forEach(layer => {
          const local = configs.find(
            c => c.name === `${layer.equipmentRepId}-${layer.layer}`,
          );
          if (!local) {
            config.push(layer);
          } else if (local.config === null)
            config.push({ ...layer, built: true });
          else {
            const incomingHash = hash(layer);
            const localHash = hash(local.config);
            if (incomingHash !== localHash)
              config.push({ ...layer, built: true });
          }
        });
        if (config.length > 0) meshes.push(config);
      });
      dispatch({
        type: types.MESH_LOAD,
        layers,
        meshes,
        built: rawMeshes.length - meshes.length,
        configs,
      });
      return null;
    });
};

export const filterMeshesByRepId = repId => ({
  type: types.MESH_FILTER_REP,
  repId,
});

export const filterMeshesByLayer = layer => ({
  type: types.MESH_FILTER_LAYER,
  layer,
});

export const selectMesh = meshId => ({
  type: types.MESH_SELECT,
  meshId,
});

export const sendMeshes = (meshes, token) => dispatch =>
  postFree(network.BO_SERVER_URL, { meshes });

export const uploadMesh = (mesh, token) => async (dispatch, getState) => {
  const file = await getFree(`${network.BO_SERVER_URL}?id=${mesh}`);
  const fileMeshes = [
    {
      name: `${mesh}.babylon`,
      file: new Blob([JSON.stringify(file.mesh)], { type: 'text/json' }),
    },
    {
      name: `${mesh}.desc`,
      file: new Blob([file.description], {
        type: 'text/plain',
      }),
    },
  ];
  const api = getState().API.api;
  return upload(api, network.API_MESH_UPLOAD, token, 'meshes', fileMeshes);
};

export const showBuildModal = () => ({
  type: types.MESH_SHOW_BUILD,
});
export const hideBuildModal = () => ({
  type: types.MESH_HIDE_BUILD,
});

export const setBuildProgress = (step, percent) => ({
  type: types.MESH_BUILD_PROGRESS,
  step,
  percent,
});
