import React, { Component } from 'react';
import { Button, Table, Icon, Message } from 'semantic-ui-react';
import * as network from '../config/network';
import { get, post } from '../lib/api';
import { SAMconnect } from '../lib/SAMconnect';
import FilecheckModal from '../components/archive/filecheckModal';

class Archive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      showConfirm: false,
      showFilecheck: false,
      files: {},
      filesQueries: {},
      archivesQueries: {},
      loading: false,
    };
    this.reloadList = this.reloadList.bind(this);
    this.hideFilecheck = this.hideFilecheck.bind(this);
  }

  componentWillMount() {
    this.reloadList();
  }
  hideFilecheck() {
    this.setState({ showFilecheck: false });
  }
  reloadList() {
    get(this.props.api, network.API_CACHE_LIST, this.props.token).then(
      customers => {
        this.setState({ customers });
      },
    );
  }

  createCheckFilesRequest(customerId) {
    post(
      this.props.api,
      network.API_FILE_CHECK,
      { customerId },
      this.props.token,
    )
      .then(response => {
        if (response.workerId) {
          const filesQueries = { ...this.state.filesQueries };
          filesQueries[customerId] = response.workerId;
          this.setState({
            filesQueries,
            loading: true,
          });
          this.checkFilesStatus(customerId);
        }
      })
      .catch(err => alert(`fetching file list failed : ${err}`));
  }

  checkFilesStatus(customerId, isArchive, tries = 0) {
    const maxTries = 1000;
    const queryId = isArchive
      ? this.state.archivesQueries[customerId]
      : this.state.filesQueries[customerId];
    post(
      this.props.api,
      network.API_FILE_CHECK_STATUS,
      { customerId, queryId },
      this.props.token,
    )
      .then(response => {
        if (response.response) {
          if (isArchive) {
            let archivesQueries = { ...this.state.archivesQueries };
            archivesQueries[customerId] = null;
            this.setState({
              loading: false,
              archivesQueries,
            });
            if (response.response === 'OK') {
              this.reloadList();
            } else {
              alert(
                `some files are missing, the archive will be incomplete ${
                  response.response
                }`,
              );
            }
          } else {
            let filesQueries = { ...this.state.filesQueries };
            filesQueries[customerId] = null;
            this.setState({
              files: response.response,
              showFilecheck: true,
              filesQueries,
              loading: false,
            });
          }
        } else {
          if (response.processing && tries < maxTries) {
            setTimeout(
              () => this.checkFilesStatus(customerId, isArchive, tries + 1),
              5000,
            );
          }
        }
      })
      .catch(err => alert(`fetching file list failed : ${err}`));
  }

  buildArchive(customerId) {
    post(
      this.props.api,
      network.API_ARCHIVE_BUILD,
      { customerId },
      this.props.token,
    ).then(response => {
      if (response.workerId) {
        const archivesQueries = { ...this.state.archivesQueries };
        archivesQueries[customerId] = response.workerId;
        this.setState({
          archivesQueries,
          loading: true,
        });
        this.checkFilesStatus(customerId, 'archive');
      }
    });
  }

  deleteArchive(customerId) {
    post(
      this.props.api,
      network.API_ARCHIVE_REMOVE,
      { customerId },
      this.props.token,
    ).then(response => {
      if (parseInt(response.message, 10) === 1) {
        this.reloadList();
      }
    });
  }

  renderCustomer(metadata) {
    return (
      <Table.Row key={metadata.customer}>
        <Table.Cell>{metadata.customer}</Table.Cell>
        <Table.Cell>
          <Button.Group size="small" widths="3">
            <Button
              positive
              disabled={
                this.props.pending || !!this.state.filesQueries[metadata.id]
              }
              onClick={() => this.createCheckFilesRequest(metadata.id)}
            >
              Check files
            </Button>
            <Button
              positive
              disabled={!!this.state.archivesQueries[metadata.id]}
              onClick={() => this.buildArchive(metadata.id)}
            >
              Build
            </Button>
            <Button
              positive
              disabled={this.props.pending}
              onClick={() => this.deleteArchive(metadata.id)}
            >
              Delete
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    console.log(this.state.customers);
    if (!this.state.customers) return null;
    const cells = this.state.customers.map(customer =>
      this.renderCustomer(customer),
    );
    return (
      <div>
        {this.state.loading && (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>
                Processing server side... Please wait
              </Message.Header>
            </Message.Content>
          </Message>
        )}
        <h1>Archives</h1>
        <div>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{cells}</Table.Body>
          </Table>
        </div>
        <FilecheckModal
          show={this.state.showFilecheck}
          files={this.state.files}
          hideModal={this.hideFilecheck}
        />
      </div>
    );
  }
}

export default SAMconnect(null, null)(Archive);
