import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Home from './home';
import Detail from './detail';
import { SAMconnect } from '../../lib/SAMconnect';
import Babylon from '../../components/mesh/babylon';

class Mesh extends Component {
  constructor(props) {
    super(props);
    this.props.actions.loadMeshes(this.props.token);
    this.babylon = React.createRef();
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Babylon ref={this.babylon} headless />
        <Route
          exact
          path={`${match.path}`}
          render={props => <Home {...props} babylon={this.babylon} />}
        />
        <Route
          path={`${match.path}/:meshId`}
          render={props => <Detail {...props} babylon={this.babylon} />}
        />
      </div>
    );
  }
}

export default SAMconnect(null, null)(Mesh);
