export const getLayerColor = layerId => {
  switch (layerId) {
    default:
      return 'grey';
    case '2':
      return 'green';
    case '4':
      return 'yellow';
  }
};
