export const API_SET_TOKEN = 'API_SET_TOKEN';
export const API_SET_API = 'API_SET_API';

export const MESH_LOAD = 'MESH_LOAD';
export const MESH_FILTER_REP = 'MESH_FILTER_REP';
export const MESH_FILTER_LAYER = 'MESH_FILTER_LAYER';
export const MESH_SELECT = 'MESH_SELECT';
export const MESH_SHOW_BUILD = 'MESH_SHOW_BUILD';
export const MESH_HIDE_BUILD = 'MESH_HIDE_BUILD';
export const MESH_BUILD_PROGRESS = 'MESH_BUILD_PROGRESS';
