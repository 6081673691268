import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Message, Card, Button, Label, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Collection, AutoSizer } from 'react-virtualized';

import { getLayerColor } from '../../lib/utils';

class MeshCards extends Component {
  constructor(props) {
    super(props);

    this.cellSizeAndPositionGetter = this.cellSizeAndPositionGetter.bind(this);
    this.renderCard = this.renderCard.bind(this);
  }

  cellSizeAndPositionGetter({ index }) {
    const x = (index % 4) * 280;
    const y = Math.floor(index / 4) * 200;
    return {
      height: 200,
      width: 270,
      x,
      y,
    };
  }

  renderLayerLabel(metadata) {
    const layerConfig = this.props.layers[parseInt(metadata.layer, 10) - 1];
    return (
      <Label
        key={`layer${metadata.layer}`}
        size="mini"
        color={getLayerColor(metadata.layer)}
      >
        {layerConfig.description}
      </Label>
    );
  }

  renderLayerContent(metadata) {
    const layerConfig = this.props.layers[parseInt(metadata.layer, 10) - 1];
    return (
      <List.Item key={`models${metadata.layer}`}>
        {`${layerConfig.description} : `}
        <strong>{metadata.models.length} models</strong>
      </List.Item>
    );
  }

  renderCard({ index, style }) {
    const metadata = this.props.meshes[index];
    const layerLabels = metadata.map(layer => this.renderLayerLabel(layer));
    const layerContent = metadata.map(layer => this.renderLayerContent(layer));
    return (
      <div className="meshCard" key={metadata[0].equipmentRepId} style={style}>
        <Card fluid>
          <Card.Content>
            <Card.Header>Mesh {metadata[0].equipmentRepId}</Card.Header>
            <Card.Meta>{layerLabels}</Card.Meta>
            <Card.Description>
              <List>{layerContent}</List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div>
              {metadata.some(mesh => mesh.built) ? (
                <span className="red">
                  <Icon name="redo" /> Rebuild available
                </span>
              ) : (
                <span className="red">
                  <Icon name="add" /> Unbuilt
                </span>
              )}
              <Link to={`${this.props.url}/${metadata[0].equipmentRepId}`}>
                <Button primary size="mini" compact basic floated="right">
                  Details
                </Button>
              </Link>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }

  render() {
    if (this.props.loading)
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Loading mesh listing</Message.Header>
            Depending on which API you are administrating, this make take a
            while.
          </Message.Content>
        </Message>
      );
    if (!this.props.meshes || this.props.meshes.length === 0)
      return (
        <Message success icon>
          <Icon name="check circle outline" />
          <Message.Content>
            <Message.Header>
              All configs for this API are locally built
            </Message.Header>
            You can now upload them to the API.
          </Message.Content>
        </Message>
      );
    return (
      <AutoSizer disableHeight>
        {({ width, height }) => (
          <Collection
            cellCount={this.props.meshes.length}
            cellRenderer={this.renderCard}
            cellSizeAndPositionGetter={this.cellSizeAndPositionGetter}
            height={600}
            width={width}
          />
        )}
      </AutoSizer>
    );
  }
}

MeshCards.propTypes = {
  meshes: PropTypes.array.isRequired,
  layers: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default MeshCards;
