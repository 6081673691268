import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

class userAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      elasticSearchIndex: 'events_local',
      userHistory: [],
      errorMessage: '',
    };
  }

  loadHistory = () => {
    if (this.state.userId === '' || this.state.elasticSearchIndex === '') {
      this.setState({
        errorMessage: 'please define an user id and an elastic search index',
      });
      return false;
    }
    return fetch(
      `http://localhost:27669/user-events/user-history?userId=${
        this.state.userId
      }&index=${this.state.elasticSearchIndex}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          throw response.error;
        }
        if (response.length === 0) {
          this.setState({ errorMessage: 'no history for this parameters' });
        } else {
          this.setState({ errorMessage: '' });
        }
        this.setState({ userHistory: response });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error });
      });
  };

  renderInput = (placeholder, type, value) => {
    return (
      <p>
        <input
          id={value}
          placeholder={placeholder}
          type={type}
          style={{ padding: 5 }}
          value={this.state[value]}
          onChange={event =>
            this.setState({
              ...this.state,
              [value]: event.target.value,
            })
          }
        />
      </p>
    );
  };

  renderHistory = () => {
    return this.state.userHistory.map((event, key) => {
      let output = [];
      if (event.secondElapsed) {
        if (event.secondElapsed < 1000) {
          output.push(
            <div style={{ float: 'left', fontSize: 18, width: '100%' }}>
              <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <FontAwesomeIcon icon={faArrowUp} />
              </div>
              <div style={{ textAlign: 'center' }}>
                {event.secondElapsed} sec
              </div>
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                <FontAwesomeIcon icon={faArrowUp} />
              </div>
            </div>,
          );
        } else {
          output.push(
            <div
              style={{
                float: 'left',
                width: '100%',
                textAlign: 'center',
                marginTop: 75,
                marginBottom: 75,
                fontSize: 22,
              }}
            >
              NEW SESSION
            </div>,
          );
        }
      }
      let parameters = '';
      if (event.parameters) {
        parameters = (
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            {event.parameters}
          </div>
        );
      }

      output.push(
        <div
          style={{
            float: 'left',
            width: '100%',
            marginTop: 15,
            marginBottom: 15,
            textAlign: 'center',
            borderTop: 'solid 2px #ccc',
            borderBottom: 'solid 2px #ccc',
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: '#eee',
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            {event.eventName}
          </div>
          {parameters}
          <div style={{ fontSize: 13 }}>{event.datetime}</div>
        </div>,
      );
      return <div key={key}>{output}</div>;
    });
  };

  render() {
    return (
      <div>
        <h3>User's History</h3>
        <p>{this.renderInput('userId', 'text', 'userId')}</p>
        <p>
          {this.renderInput(
            'Elastic Search Index',
            'text',
            'elasticSearchIndex',
          )}
        </p>
        <p>
          <input
            type="button"
            onClick={this.loadHistory}
            value="load history"
          />
        </p>
        <p>{this.state.errorMessage}</p>
        {this.renderHistory()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  api: state.API.api,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigate: path => push(path),
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(userAnalytics)));
