import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import * as constants from '../const.json';

const ReactMarkdown = require('react-markdown');

class Manual extends Component {
  constructor(props) {
    super(props);
    this.navigate = this.navigate.bind(this);
    this.loadLatestManual = this.loadLatestManual.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.state = {
      customerId: constants.userManual.defaultCustomerPreview,
      env: constants.userManual.defaultEnvPreview,
      version: constants.userManual.defaultVersionPreview,
      markdown_manual: 'loading...',
      config: {
        env: [],
        customers: [],
      },
      checkbox: {
        customer: [],
        env: [],
      },
    };
    this.html_manual = '';
    this.loadLatestManual();
    this.loadManualConfig();
  }

  navigate() {
    if (
      this.state.checkbox.customer.length > 0 &&
      this.state.checkbox.env.length > 0
    ) {
      window.open(
        `http://localhost:${
          constants.userManual.apiPort
        }/user-manual/build-and-upload?customer=${JSON.stringify(
          this.state.checkbox.customer,
        )}&env=${JSON.stringify(this.state.checkbox.env)}`,
        '_blank',
      );
    } else {
      alert('please select at least 1 customer and 1 environment');
    }
  }

  loadLatestManual = () => {
    this.setState({
      markdown_manual: 'loading...',
    });
    return fetch(
      `http://localhost:${
        constants.userManual.apiPort
      }/user-manual/preview?customer=${this.state.customerId}&env=${
        this.state.version
      }&version=${this.state.version}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
      .then(response => response.json())
      .then(response => {
        this.setState({
          markdown_manual: response.data,
        });
      })
      .catch(error => console.error(`Fetch Error =\n`, error));
  };

  loadManualConfig = () => {
    this.setState({
      markdown_manual: 'loading...',
    });
    return fetch(
      `http://localhost:${constants.userManual.apiPort}/user-manual/config`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
      .then(response => response.json())
      .then(response => {
        this.setState({
          config: response.data,
        });
      })
      .catch(error => console.error(`Fetch Error =\n`, error));
  };

  formatManual = html => {
    // 1.1 Format <img />
    // ---

    html = html.replace(
      /src="([^#].*?)"/g,
      'style="max-width:500px;" src="images/user-manual/$1"',
    );

    // 1.2 Format anchors
    // ---

    html = html.replace(
      /<img alt="anchor" src="#([a-z0-9]*)"\/>/gi,
      '<p id="$1"></p>',
    );

    return html;
  };

  renderManualPreview = () => {
    let html = ReactDOMServer.renderToString(
      <ReactMarkdown source={this.state.markdown_manual} />,
    );
    html = this.formatManual(html);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  renderInput(placeholder, type, value) {
    return (
      <p>
        <input
          id={value}
          placeholder={placeholder}
          type={type}
          style={{ padding: 5 }}
          value={this.state[value]}
          onChange={event =>
            this.setState({
              ...this.state,
              [value]: event.target.value,
            })
          }
        />
      </p>
    );
  }

  handleChange = (event, scope) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let currentCheckBoxState = this.state.checkbox;

    if (!value) {
      if (this.state.checkbox[scope].includes(name)) {
        currentCheckBoxState[scope] = currentCheckBoxState[scope].filter(
          ele => {
            return ele !== name;
          },
        );
      }
    } else {
      currentCheckBoxState[scope].push(name);
    }

    this.setState({
      checkbox: currentCheckBoxState,
    });
  };

  getCustomers = () => {
    const output = [];
    this.state.config.customers.map((customer, key) => {
      let isCheck = false;
      if (this.state.checkbox.customer.includes(customer.id)) {
        isCheck = true;
      }
      output.push(
        <div key={key}>
          <input
            style={{ marginRight: 5 }}
            name={customer.id}
            type="checkbox"
            checked={isCheck}
            onChange={e => this.handleChange(e, 'customer')}
          />
          {customer.id}
        </div>,
      );
    });
    return <div>{output}</div>;
  };

  getEnvironments = () => {
    const output = [];
    this.state.config.env.map((env, key) => {
      let isCheck = false;
      if (this.state.checkbox.env.includes(env.id)) {
        isCheck = true;
      }
      output.push(
        <div>
          <input
            style={{ marginRight: 5 }}
            name={env.id}
            type="checkbox"
            checked={isCheck}
            onChange={e => this.handleChange(e, 'env')}
          />
          {env.id}
        </div>,
      );
    });
    return <div>{output}</div>;
  };

  render() {
    return (
      <div>
        <h3>How to use it ?</h3>
        <p>
          <ul>
            <li>
              1) Define your gitHub Access token in api/config.js
              (https://github.com/settings/tokens)
            </li>
            <li>
              2) Declare the parameters you want to use (versions, buckets,
              customers) in
              https://github.com/AirIntServices/SAM360-Manual/params.json
            </li>
            <li>
              3) Edit the manuals on
              https://github.com/AirIntServices/SAM360-Manual/ect
            </li>
            <li>
              4) Upload the media files in
              https://github.com/AirIntServices/SAM360-Manual/medias
            </li>
            <li>5) Allow your backOffice to access to the Buckets:</li>
            <li>
              <ul>
                <li>
                  5.1) Go to{' '}
                  <a
                    href="https://console.cloud.google.com/apis/credentials/serviceaccountkey"
                    target="_blank"
                  >
                    https://console.cloud.google.com/apis/credentials/serviceaccountkey
                  </a>
                </li>
                <li>5.2) Select "BucketManager" and click "Create"</li>
                <li>45.3) Download the json file in a "safe directory"</li>
                <li>
                  5.4) Then, type in your terminal:{' '}
                  <code>
                    export GOOGLE_APPLICATION_CREDENTIALS="[FILE_NAME].json"
                  </code>
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <h3>Export user manuals</h3>
        <p>
          edit the manuals on{' '}
          <a
            target="_blank"
            href="https://github.com/AirIntServices/SAM360-Manual"
          >
            SAM360-Manual
          </a>
        </p>
        <h3>Preview:</h3>
        {this.renderInput('Customer Id', 'text', 'customerId')}
        {this.renderInput('env', 'text', 'env')}
        {this.renderInput('version', 'text', 'version')}
        <p>
          <input
            onClick={() => this.loadLatestManual()}
            type="button"
            value="Refresh preview"
          />
        </p>
        <div style={{ padding: 10, backgroundColor: '#f5f5f5' }}>
          {this.renderManualPreview()}
        </div>
        <div
          style={{
            padding: 10,
            marginTop: 25,
            marginBottom: 25,
            backgroundColor: '#f5f5f5',
          }}
        >
          <h2 style={{ borderBottom: 'solid 2px #CCC' }}>Push manuals</h2>
          <h3 style={{ borderBottom: 'solid 2px #CCC' }}>Customers</h3>
          {this.getCustomers()}
          <h3 style={{ borderBottom: 'solid 2px #CCC' }}>Environments</h3>
          {this.getEnvironments()}
          <div style={{ marginTop: 20 }}>
            <input onClick={() => this.navigate()} type="button" value="Push" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  api: state.API.api,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigate: path => push(path),
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(Manual)));
