import React, { Component } from 'react';
import * as network from '../config/network';
import { SAMconnect } from '../lib/SAMconnect';
import { get } from '../lib/api';

class RSATokenGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenString: '',
      pinCode: '1337',
      code: null,
      errorMessage: null,
    };
    this.fetchToken = this.fetchToken.bind(this);
  }

  fetchToken = () => {
    get(
      this.props.api,
      `${network.API_RSA_TOKEN}?tokenstring=${this.state.tokenString}&pin=${
        this.state.pinCode
      }`,
      this.props.token,
    )
      .then(result => {
        if (!result) {
          this.setState({
            errorMessage: `Could not generate code`,
          });
        } else {
          this.setState({ code: result.code, errorMessage: null });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ errorMessage: err.message });
      });
  };

  handleSubmit = event => {
    this.fetchToken();
    event.preventDefault();
  };

  handleTokenStringChange = event => {
    this.setState({ tokenString: event.target.value });
  };

  handlePinCodeChange = event => {
    this.setState({ pinCode: event.target.value });
  };

  render() {
    return (
      <div className="request-viewer">
        <h1>RSA token generator for AF/KL</h1>
        <div className="container">
          <form
            className="request-selector"
            onSubmit={this.handleSubmit}
            id="requestForm"
          >
            <legend>Enter the token string from secret-in:</legend>
            <div className="input-bar">
              <input
                type="text"
                name="tokenString"
                value={this.state.tokenString}
                onChange={this.handleTokenStringChange}
              />
            </div>
            <legend>Enter the PIN code:</legend>
            <div className="input-bar">
              <input
                type="text"
                name="pinCode"
                value={this.state.pinCode}
                onChange={this.handlePinCodeChange}
              />
            </div>
            <br />
            <div className="fetch-bar">
              <input type="submit" onClick={this.fetchToken} value="Get code" />
            </div>
          </form>
          {this.state.errorMessage}
          {this.state.code ? <h1>{this.state.code}</h1> : null}
        </div>
      </div>
    );
  }
}

export default SAMconnect(null, null)(RSATokenGenerator);
