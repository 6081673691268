import React from 'react';

export const TagHistory = props => {
  if (!props.history || props.history.length === 0)
    return <div>No history data available for tag {props.epc}</div>;
  return (
    <fieldset>
      <p>History for tag {props.epc}</p>
      <ul>
        {props.history.map(tick => (
          <li>
            [{tick.date}] - {tick.description} - {tick.position} (
            {tick.location}) on {tick.aircraft} (Tag.id = {tick.id})
          </li>
        ))}
      </ul>
    </fieldset>
  );
};
