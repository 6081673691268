import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader, Button, Table } from 'semantic-ui-react';

class CacheList extends Component {
  renderCustomer(metadata) {
    return (
      <Table.Row key={metadata.customer}>
        <Table.Cell>{metadata.customer}</Table.Cell>
        <Table.Cell textAlign="center">
          {metadata.revision ? (
            metadata.revision
          ) : (
            <Icon name="question" color="red" />
          )}
        </Table.Cell>
        <Table.Cell>
          {metadata.timestamp ? (
            new Date(parseInt(metadata.timestamp, 10)).toString()
          ) : (
            <Icon name="question" color="red" />
          )}
        </Table.Cell>
        <Table.Cell>
          <Button.Group size="small" widths="3">
            <Button
              positive
              disabled={this.props.pending}
              onClick={() => this.props.rebuildCustomer(metadata.id)}
            >
              {this.props.pending ? (
                <Loader size="mini" active inline inverted />
              ) : (
                'Rebuild'
              )}
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    if (!this.props.customers) return null;
    const cells = this.props.customers.map(customer =>
      this.renderCustomer(customer),
    );
    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell collapsing>Revision</Table.HeaderCell>
            <Table.HeaderCell width="three">Date</Table.HeaderCell>
            <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{cells}</Table.Body>
      </Table>
    );
  }
}

CacheList.propTypes = {
  customers: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  setPending: PropTypes.func.isRequired,
  reloadList: PropTypes.func.isRequired,
  rebuildCustomer: PropTypes.func.isRequired,
  removeCustomerCache: PropTypes.func.isRequired,
};

export default CacheList;
