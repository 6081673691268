import * as types from '../config/types';

const initialState = {
  meshes: [],
  layers: [],
  configs: [],
  built: 0,
  loading: true,
  searchedRepId: '',
  searchedLayer: -1,
  selectedMeshId: -1,
  showBuildModal: false,
  buildModalProgress: 0,
  buildModalStepLabel: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MESH_LOAD:
      return {
        ...state,
        meshes: action.meshes,
        layers: action.layers,
        configs: action.configs,
        built: action.built,
        loading: false,
      };
    case types.MESH_FILTER_REP:
      return {
        ...state,
        searchedRepId: action.repId,
      };
    case types.MESH_FILTER_LAYER:
      return {
        ...state,
        searchedLayer: action.layer,
      };
    case types.MESH_SELECT:
      return {
        ...state,
        selectedMeshId: action.meshId,
      };
    case types.MESH_SHOW_BUILD:
      return {
        ...state,
        showBuildModal: true,
        buildModalProgress: 0,
        buildModalStepLabel: 'Initializing...',
      };
    case types.MESH_HIDE_BUILD:
      return {
        ...state,
        showBuildModal: false,
      };
    case types.MESH_BUILD_PROGRESS:
      return {
        ...state,
        buildModalProgress: action.percent,
        buildModalStepLabel: action.step,
      };
    default:
      return state;
  }
};
