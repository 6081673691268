import React, { Component } from 'react';
import * as network from '../config/network';
import { SAMconnect } from '../lib/SAMconnect';
import AceEditor from 'react-ace';

import 'brace/theme/monokai';
import 'brace/mode/javascript';

class Sandbox extends Component {
  constructor(props) {
    super(props);
    this.handleDefaultValue = this.handleDefaultValue.bind(this);
    this.state = {
      requestInteropId: '',
      defaultValue: '',
      output: '',
      logs: [],
      err: '',
    };
  }

  postData = (url = ``) => {
    const data = new FormData(document.getElementById('sandboxForm'));
    let body = {};
    for (var [key, value] of data.entries()) {
      body[key] = value;
    }

    body.defaultValue = this.state.defaultValue;

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        this.setState({
          logs: response.logs.length ? response.logs : [],
          err: response.err.length
            ? JSON.stringify(response.err)
            : 'No errors for this run',
          output: JSON.stringify(response.test),
        });
      })
      .catch(error => console.error(`Fetch Error =\n`, error));
  };

  handleInteropId(event) {
    this.setState({ requestInteropId: event.target.value });
  }

  handleDefaultValue(newValue) {
    this.setState({ defaultValue: newValue });
  }

  render() {
    return (
      <div>
        <h1>Sandbox</h1>
        <form id="sandboxForm">
          <fieldset>
            <legend>Test your JS:</legend>
            Request Interop Id:
            <br />
            <input
              type="text"
              name="requestInteropId"
              value={this.state.requestInteropId}
              onChange={this.handleInteropId.bind(this)}
            />
            <br /> Code:
            <br />
            <AceEditor
              mode="javascript"
              theme="monokai"
              name="defaultValue"
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={this.state.defaultValue}
              onChange={this.handleDefaultValue}
              style={{ width: '100%' }}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
            <br />
            <input
              type="button"
              onClick={() =>
                this.postData(
                  `${this.props.api}${network.API_SANDBOX}?token=${
                    this.props.token
                  }`,
                )
              }
              value="Submit"
            />
          </fieldset>
        </form>
        <br />
        <fieldset>
          <legend>Output:</legend>
          <div id="output" />
          {this.state.output}
          <br />
        </fieldset>
        <fieldset>
          <legend>Logs:</legend>
          <div id="logs" />
          {this.state.logs.length ? (
            this.state.logs.map(logLine => (
              <span>
                {logLine}
                <br />
              </span>
            ))
          ) : (
            <p>No logs for this run</p>
          )}
          <br />
        </fieldset>
        <fieldset>
          <legend>Errors:</legend>
          <div id="err" />
          {this.state.err}
          <br />
        </fieldset>
      </div>
    );
  }
}

export default SAMconnect(null, null)(Sandbox);
