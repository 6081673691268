import React, { Component } from 'react';
import { Button, Confirm } from 'semantic-ui-react';

import * as network from '../config/network';
import { get, post } from '../lib/api';
import List from '../components/cache/list';
import { SAMconnect } from '../lib/SAMconnect';

class Cache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      pending: true,
      showConfirm: false,
    };
    this.setPending = this.setPending.bind(this);
    this.rebuildCustomers = this.rebuildCustomers.bind(this);
    this.purgeCache = this.purgeCache.bind(this);
    this.reloadList = this.reloadList.bind(this);
    this.rebuildCustomer = this.rebuildCustomer.bind(this);
    this.removeCustomerCache = this.removeCustomerCache.bind(this);
  }

  openConfirm = () => this.setState({ showConfirm: true });
  closeConfirm = () => this.setState({ showConfirm: false });

  componentWillMount() {
    this.reloadList();
  }

  setPending(pending) {
    this.setState({ pending });
  }

  reloadList() {
    this.setPending(true);
    get(this.props.api, network.API_CACHE_LIST, this.props.token).then(
      customers => {
        this.setState({ customers });
        this.setPending(false);
      },
    );
  }

  rebuildCustomers() {
    this.closeConfirm();
    this.setPending(true);
    post(
      this.props.api,
      network.API_CACHE_REBUILD,
      null,
      this.props.token,
    ).then(response => {
      if (response.message === 'OK') {
        this.reloadList();
        this.setPending(false);
      }
    });
  }

  purgeCache() {
    this.closeConfirm();
    this.setPending(true);
    post(this.props.api, network.API_CACHE_REMOVE, null, this.props.token).then(
      response => {
        if (parseInt(response.message, 10) === 1) {
          this.reloadList();
          this.setPending(false);
        }
      },
    );
  }

  rebuildCustomer(customerId) {
    this.closeConfirm();
    this.setPending(true);
    post(
      this.props.api,
      network.API_CACHE_REBUILD,
      { customerId },
      this.props.token,
    )
      .then(response => {
        if (response.message === 'OK') {
          this.reloadList();
          this.setPending(false);
        }
      })
      .catch(error => {
        console.log(error);
        this.setPending(false);
      });
  }

  removeCustomerCache(customerId) {
    this.setPending(true);
    post(
      this.props.api,
      network.API_CACHE_REMOVE,
      { customerId },
      this.props.token,
    ).then(response => {
      if (parseInt(response.message, 10) === 1) {
        this.reloadList();
        this.setPending(false);
      }
    });
  }

  render() {
    return (
      <div>
        <h1>Cache</h1>
        <p>
          <Button
            positive
            disabled={this.state.pending}
            onClick={this.openConfirm}
          >
            Rebuild all
          </Button>
          <Confirm
            open={this.state.showConfirm}
            onCancel={this.closeConfirm}
            content="This will flush every single device on this API"
            onConfirm={() => this.rebuildCustomers()}
          />
        </p>
        <div>
          <List
            customers={this.state.customers}
            token={this.props.token}
            pending={this.state.pending}
            setPending={this.setPending}
            reloadList={this.reloadList}
            rebuildCustomer={this.rebuildCustomer}
            removeCustomerCache={this.removeCustomerCache}
          />
        </div>
      </div>
    );
  }
}

export default SAMconnect(null, null)(Cache);
