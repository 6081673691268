export const API_URL = 'https://api.airintservices.com/staging';
export const API_STATUS = '/status';
export const API_TOKEN = '/auth/online';
export const API_TOKEN_TTL = 3600;
export const API_BACKOFFICE_TOKEN =
  '4716842f4a035cb7e8353a0bad787d6a174c262af9b94d1f8e985acdeffc529a';
export const API_BACKOFFICE_ROUTE = '/api/ais';

export const API_CACHE_LIST = '/model/cache/list';
export const API_CACHE_REBUILD = '/model/cache/rebuild';
export const API_CACHE_REMOVE = '/model/cache/remove';
export const API_SANDBOX = '/api/sandbox';

export const API_MESH_LAYERS = '/mesh/layer';
export const API_MESH_LIST = '/mesh/list';
export const API_MESH_UPLOAD = '/api/sync/meshes';

export const API_ARCHIVE_BUILD = '/archive/generate';
export const API_ARCHIVE_REMOVE = '/archive/remove';
export const API_FILE_CHECK = '/filecheck';
export const API_FILE_CHECK_STATUS = '/filestatus';

export const API_REQUEST_VIEWER = '/request-viewer';
export const API_TAG_VIEWER = '/tag-viewer';
export const API_RSA_TOKEN = '/rsa-token';

export const BO_URL = 'http://localhost:3000/assets/meshes/';
export const BO_SERVER_URL = 'http://localhost:27666/config';
