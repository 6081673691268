import * as network from '../config/network';

export const request = async (
  method,
  api,
  endpoint,
  body,
  token,
  absolute = false,
) => {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  const options = {
    method,
    headers,
  };
  if (method === 'POST' && body) options.body = JSON.stringify(body);
  const response = await fetch(
    `${api}${absolute ? '' : network.API_BACKOFFICE_ROUTE}${endpoint}`,
    options,
  );
  return response.json();
};

export const upload = async (api, endpoint, token, field, array) => {
  const formData = new FormData();

  array.forEach(element => {
    formData.append(field, element.file, element.name);
  });

  const headers = {};
  if (token) headers.Authorization = `Bearer ${token}`;
  const options = {
    method: 'POST',
    headers,
    body: formData,
  };

  const response = await fetch(`${api}${endpoint}`, options);
  return response.json();
};

export const get = (api, endpoint, token, absolute = false) =>
  request('GET', api, endpoint, null, token, absolute);
export const post = (api, endpoint, body, token, absolute = false) =>
  request('POST', api, endpoint, body, token, absolute);
// for non api stuff
export const getFree = async endpoint => {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };
  const options = {
    method: 'GET',
    headers,
  };
  const response = await fetch(endpoint, options);
  return response.json();
};
export const postFree = async (endpoint, body) => {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };
  return fetch(endpoint, options);
};
