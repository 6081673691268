import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes, { instanceOf } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';

export const SAMconnect = (mapStateToProps, mapDispatchToProps) => {
  return WrappedComponent => {
    const wrapper = class WrapperComponent extends Component {
      componentWillMount() {
        let token = this.props.token;
        if (!token) {
          const { cookies } = this.props;
          token = cookies.get('token');
          if (token) {
            this.props.actions.setAPIToken(token);
          }
          const server = cookies.get('server');
          if (server) {
            this.props.actions.setAPI(server);
          }
        }
      }
      render() {
        return <WrappedComponent {...this.props} />;
      }
    };
    wrapper.propTypes = {
      cookies: instanceOf(Cookies).isRequired,
      api: PropTypes.string,
      token: PropTypes.string,
      content: PropTypes.element,
      ...WrappedComponent.propTypes,
    };

    const mapDispatchToPropsAuto = dispatch => {
      const externalDispatchToProps = mapDispatchToProps
        ? mapDispatchToProps(dispatch)
        : {};
      return {
        dispatch,
        actions: bindActionCreators(actions, dispatch),
        ...externalDispatchToProps,
      };
    };
    const mapStateToPropsAuto = state => {
      const externalStateToProps = mapStateToProps
        ? mapStateToProps(state)
        : {};
      return {
        api: state.API.api,
        token: state.API.token,
        ...externalStateToProps,
      };
    };

    return connect(mapStateToPropsAuto, mapDispatchToPropsAuto)(
      withCookies(wrapper),
    );
  };
};
