import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import API from './apiReducer';
import mesh from './meshReducer';

export default combineReducers({
  routing: routerReducer,
  API,
  mesh,
});
