import React, { Component } from 'react';
import {
  Button,
  Input,
  Divider,
  Modal,
  Message,
  Progress,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Cards from '../../components/mesh/cards';
import { SAMconnect } from '../../lib/SAMconnect';
import { getFilteredMeshes } from '../../selectors/mesh';

const MESH_BATCH_COUNT = 20;

class MeshHome extends Component {
  constructor(props) {
    super(props);
    this.searchByRepId = this.searchByRepId.bind(this);
    this.state = {
      showWarning: false,
      showUploadRecap: false,
      showUpload: false,
      uploadProgress: 0,
      uploadStatus: 'Uploading...',
    };
  }

  searchByRepId(searchQuery) {
    this.props.actions.filterMeshesByRepId(searchQuery);
  }

  showWarningModal = () => {
    this.setState({ showWarning: true });
  };

  hideWarningModal = () => {
    this.setState({ showWarning: false });
  };

  showUploadModal = () => {
    this.setState({ showUpload: true });
  };

  hideUploadModal = () => {
    this.setState({ showUpload: false });
  };
  showUploadRecapModal = () => {
    this.setState({ showUploadRecap: true });
  };

  hideUploadRecapModal = () => {
    this.setState({ showUploadRecap: false });
  };

  buildAll = async () => {
    this.hideWarningModal();
    this.props.actions.showBuildModal();
    let meshes = [];
    let promise = Promise.resolve();
    let currentStep = 0;
    const stepCount =
      this.props.meshes.reduce((acc, mesh) => acc + mesh.length, 0) + 1;
    this.props.actions.setBuildProgress(
      `Rep${this.props.meshes[0][0].equipmentRepId} on layer ${
        this.props.meshes[0][0].layer
      }`,
      0,
    );
    this.props.meshes.map(mesh => {
      mesh.map(config => {
        promise = promise.then(result => {
          if (result) meshes.push(result);
          currentStep += 1;
          this.props.actions.setBuildProgress(
            `Rep ${config.equipmentRepId} on layer ${config.layer}`,
            Math.round(currentStep / stepCount * 100),
          );
          return this.props.babylon.current.buildSceneAsync(config);
        });
        return null;
      });
      promise = promise.then(result => {
        if (result) meshes.push(result);
        if (meshes.length > MESH_BATCH_COUNT) {
          const sentMeshes = [...meshes];
          meshes = [];
          return this.props.actions.sendMeshes(sentMeshes, this.props.token);
        }
        return null;
      });
      return null;
    });
    promise = promise.then(result => {
      if (result) meshes.push(result);
      currentStep += 1;
      this.props.actions.setBuildProgress(
        'Sending to API...',
        Math.round(currentStep / stepCount * 100),
      );
      return null;
    });
    await promise;
    this.props.actions.sendMeshes(meshes, this.props.token).then(() => {
      this.props.actions.setBuildProgress('Success!', 100);
    });
  };

  upload = async () => {
    this.setState({ uploadProgress: 100 });
    this.hideUploadRecapModal();
    this.showUploadModal();
    let promise = Promise.resolve();
    let progress = 0;
    this.props.configs.forEach((config, index) => {
      promise = promise
        .then(() => this.props.actions.uploadMesh(config, this.props.token))
        .then(() => {
          progress += 1 / this.props.configs.length;
          this.setState({ uploadProgress: Math.round(progress * 100) });
          return null;
        });
    });
    await promise;
    this.setState({ uploadProgress: 100, uploadStatus: 'Done' });
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.showWarning}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>Build all meshes</Modal.Header>
          <Modal.Content>
            This process takes a while (a good 10-20 minutes). Are you sure?
            This will not upload meshes to the API, only build them locally.
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="remove"
              content="No thanks"
              onClick={() => this.hideWarningModal()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Go On"
              onClick={() => this.buildAll()}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showUploadRecap}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>Upload builds</Modal.Header>
          <Modal.Content>
            <h3>{this.props.built} configs ready for upload</h3>
            <p>These configurations will be uploaded and saved on target API</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="remove"
              content="No thanks"
              onClick={() => this.hideUploadRecapModal()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Upload"
              onClick={() => this.upload()}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showUpload}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>Uploading...</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.uploadProgress} indicating progress>
              {this.state.uploadStatus}
            </Progress>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              disabled={this.state.uploadProgress < 100}
              labelPosition="right"
              icon="checkmark"
              content="Close"
              onClick={() => this.hideUploadModal()}
            />
          </Modal.Actions>
        </Modal>
        <h1>Mesh</h1>
        <Message warning>
          <Message.Header>
            Please launch the mesh server to use this page
          </Message.Header>
          <p>
            <code>yarn server</code> in repo root folder
          </p>
        </Message>
        <div>
          <Button positive onClick={() => this.showWarningModal()}>
            Rebuild all
          </Button>
          <Button
            negative
            disabled={this.props.built === 0}
            onClick={() => this.showUploadRecapModal()}
          >
            Upload builds ({this.props.built} configs ready)
          </Button>
          <Input
            icon="search"
            placeholder="Search..."
            size="tiny"
            onChange={(e, data) => this.searchByRepId(data.value)}
          />
        </div>
        <Divider />
        <div>
          <Cards
            meshes={this.props.meshes}
            layers={this.props.layers}
            loading={this.props.loading}
            url={this.props.match.url}
          />
        </div>
      </div>
    );
  }
}

MeshHome.propTypes = {
  meshes: PropTypes.array,
  layers: PropTypes.array,
  configs: PropTypes.array,
  built: PropTypes.number,
  loading: PropTypes.bool,
  babylon: PropTypes.object,
};
const mapStateToProps = state => ({
  meshes: getFilteredMeshes(state),
  layers: state.mesh.layers,
  configs: state.mesh.configs,
  built: state.mesh.built,
  loading: state.mesh.loading,
});

export default SAMconnect(mapStateToProps, null)(MeshHome);
