import { createSelector } from 'reselect';

const getMeshes = state => state.mesh.meshes;
const getSearchedRepId = state => state.mesh.searchedRepId;
const getSelectedMeshId = state => state.mesh.selectedMeshId;

export const getFilteredMeshes = createSelector(
  [getMeshes, getSearchedRepId],
  (meshes, searchedRepId) =>
    meshes.filter(mesh => mesh[0].equipmentRepId.includes(searchedRepId)),
);

export const getMeshDetails = createSelector(
  [getMeshes, getSelectedMeshId],
  (meshes, selectedMeshId) => {
    if (meshes.length === 0) return false;
    return meshes.find(mesh => mesh[0].equipmentRepId === selectedMeshId);
  },
);
