import React from 'react';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMonokai from 'react-json-pretty/dist/monikai';

export const TagReadingDetails = props => {
  if (!props.readingData) return <div>No data</div>;
  const tr = props.readingData;
  const matchingEpcSer = tr.ser.includes(props.expectedSer);
  const matchingEpc = tr.epc === props.expectedEpc;
  const isMassDataImport = tr.date === '2019-09-23 08:26:15'; // This is the common date for all mass-imports from RC
  return (
    <div>
      {props.readingData.epc !== null && !matchingEpc && (
        <b style={{ color: 'red' }}>
          This tag reading concerns a previously installed tag
          <br />
        </b>
      )}
      {(!props.readingData.epc || matchingEpc) && !matchingEpcSer && (
        <b style={{ color: 'orange' }}>
          This tag reading does not match the SER from the currently assigned
          tag EPC
          <br />
        </b>
      )}
      {isMassDataImport && (
        <b style={{ color: 'orange' }}>
          This tag reading was part of a mass data import from RC database
          (occured on 2019-09-23 08:26:15) which is a common cause of errors
          <br />
        </b>
      )}
      <ul key={tr.id}>
        {Object.keys(tr).map(field => (
          <li>
            {field === 'reading' ? (
              <div>
                <span>{'Decoded memory :'}</span>
                <JSONPretty
                  id="json-pretty"
                  theme={JSONPrettyMonokai}
                  data={tr[field]}
                />
              </div>
            ) : (
              `${field} : ${tr[field]}`
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
