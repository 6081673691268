import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Progress } from 'semantic-ui-react';

const MeshModal = props => (
  <Modal open={props.show} closeOnEscape={false} closeOnRootNodeClick={false}>
    <Modal.Header>Building meshes</Modal.Header>
    <Modal.Content>
      <Progress percent={props.percent} indicating progress>
        {props.stepLabel}
      </Progress>
    </Modal.Content>
    <Modal.Actions>
      <Button
        positive
        labelPosition="right"
        icon="checkmark"
        content="Close"
        disabled={props.percent !== 100}
        onClick={() => props.hideModal()}
      />
    </Modal.Actions>
  </Modal>
);

MeshModal.propTypes = {
  show: PropTypes.bool,
  stepLabel: PropTypes.string,
  percent: PropTypes.number,
  hideModal: PropTypes.func,
};

export default MeshModal;
