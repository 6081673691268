import React, { Component } from 'react';
import * as network from '../config/network';
import { SAMconnect } from '../lib/SAMconnect';
import { TagDetails } from '../components/tag-viewer/TagDetails';
import { get } from '../lib/api';

class TagViewer extends Component {
  constructor(props) {
    super(props);
    this.fetchAircraftList = this.fetchAircraftList.bind(this);
    this.fetchEquipmentsList = this.fetchEquipmentsList.bind(this);
    this.fetchLocationsList = this.fetchLocationsList.bind(this);
    this.fetchTagDetails = this.fetchTagDetails.bind(this);
    this.onAircraftPicked = this.onAircraftPicked.bind(this);
    this.onEquipmentPicked = this.onEquipmentPicked.bind(this);
    this.onLocationPicked = this.onLocationPicked.bind(this);

    this.state = {
      selectedAircraftId: null,
      selectedLopaEquipmentId: null,
      selectedItemLocationId: null,
      aircraftList: [],
      equipmentList: {},
      positionList: [],
      tagData: null,
      tagReadingsData: null,
      tagHistory: null,
      showRawFields: false,
      showTagHistory: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.fetchAircraftList();
  }

  // Utility method used for all data fetches
  fetchData = (url, stateKey, processResults) =>
    get(this.props.api, url, this.props.token)
      .then(results => {
        if (!results) {
          this.setState({
            errorMessage: `Could not fetch ${stateKey} (using url ${url})`,
          });
        } else {
          const processedResults = processResults
            ? processResults(results)
            : results;
          this.setState({ [stateKey]: processedResults, errorMessage: null });
        }
        return results;
      })
      .catch(err => {
        console.log(err);
        this.setState({ errorMessage: err.message });
      });

  fetchAircraftList = () =>
    this.fetchData(
      `${network.API_TAG_VIEWER}/aircraft?customerId=10`,
      'aircraftList',
    );
  fetchEquipmentsList = aircraftId =>
    this.fetchData(
      `${network.API_TAG_VIEWER}/equipments?aircraftId=${aircraftId}`,
      'equipmentList',
      equipments =>
        equipments.reduce((output, equipment) => {
          let areaEqus = output[equipment.area];
          if (!areaEqus) areaEqus = [];
          areaEqus.push(equipment);
          areaEqus.sort((a, b) => {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          });
          return { ...output, [equipment.area]: areaEqus };
        }, {}),
    );
  fetchLocationsList = (aircraftId, lopaEquipmentId) =>
    this.fetchData(
      `${
        network.API_TAG_VIEWER
      }/locations?aircraftId=${aircraftId}&lopaEquipmentId=${lopaEquipmentId}`,
      'positionList',
    );

  fetchTagDetails = (aircraftId, lopaEquipmentId, itemLocationId) =>
    this.fetchData(
      `${
        network.API_TAG_VIEWER
      }?aircraftId=${aircraftId}&lopaEquipmentId=${lopaEquipmentId}&itemLocationId=${itemLocationId}`,
      'tagData',
    ).then(tagData => {
      if (!tagData) return [];
      return this.fetchData(
        `${network.API_TAG_VIEWER}/readings?tagId=${tagData.id}`,
        'tagReadingsData',
      );
    });

  fetchTagHistory = epc =>
    this.fetchData(
      `${network.API_TAG_VIEWER}/history?epc=${epc}`,
      'tagHistory',
    );

  onAircraftPicked = event => {
    const aircraftId = event.target.value;
    this.setState({
      selectedAircraftId: aircraftId,
      selectedLopaEquipmentId: '',
      selectedItemLocationId: '',
      tagData: null,
      tagReadingsData: null,
    });
    this.fetchEquipmentsList(aircraftId);
  };

  onEquipmentPicked = event => {
    const equipmentId = event.target.value;
    this.setState({
      selectedLopaEquipmentId: equipmentId,
      selectedItemLocationId: '',
      tagData: null,
      tagReadingsData: null,
    });
    this.fetchLocationsList(this.state.selectedAircraftId, equipmentId);
  };

  onLocationPicked = event => {
    const itemLocationId = event.target.value;
    this.setState({
      selectedItemLocationId: itemLocationId,
      tagData: null,
      tagReadingsData: null,
    });
    this.fetchTagDetails(
      this.state.selectedAircraftId,
      this.state.selectedLopaEquipmentId,
      itemLocationId,
    );
  };

  toggleShowRawFields = event => {
    this.setState(previousState => ({
      showRawFields: !previousState.showRawFields,
    }));
  };

  toggleShowTagHistory = event => {
    if (!this.state.showTagHistory) {
      this.fetchTagHistory(this.state.tagData.epc);
    }
    this.setState(previousState => ({
      showTagHistory: !previousState.showTagHistory,
    }));
  };

  render() {
    return (
      <div className="tag-viewer">
        <h1>RFID tag viewer</h1>
        <div className="container">
          <form className="tag-selector">
            <fieldset>
              <legend>Select an aircraft :</legend>
              <select
                onChange={this.onAircraftPicked}
                value={this.state.selectedAircraftId}
              >
                <option value="">--Please choose an aircraft--</option>
                {this.state.aircraftList.map(ac => (
                  <option key={ac.id} value={ac.id}>
                    {ac.epc === 'NC' && '[NC] '}
                    {ac.registration}
                  </option>
                ))}
              </select>
              <legend>Select an equipment :</legend>
              <select
                onChange={this.onEquipmentPicked}
                disabled={!this.state.selectedAircraftId}
                value={this.state.selectedLopaEquipmentId}
              >
                <option value="">--Please choose an equipment--</option>
                {Object.keys(this.state.equipmentList).map(area => (
                  <optgroup label={area}>
                    {this.state.equipmentList[area].map(equ => (
                      <option key={equ.id} value={equ.id}>
                        {equ.epc === 'NC' && '[NC] '}
                        {`${equ.position} (${equ.description})`}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <legend>Select a position :</legend>
              <select
                onChange={this.onLocationPicked}
                disabled={
                  !this.state.selectedAircraftId ||
                  !this.state.selectedLopaEquipmentId
                }
                value={this.state.selectedItemLocationId}
              >
                <option value="">--Please choose a position--</option>
                {this.state.positionList.map(itemLoc => (
                  <option key={itemLoc.id} value={itemLoc.id}>
                    {itemLoc.epc === 'NC' && '[NC] '}
                    {`${itemLoc.location} (${itemLoc.description})`}
                  </option>
                ))}
              </select>
            </fieldset>
          </form>
          {this.state.errorMessage}
          {this.state.tagData && (
            <TagDetails
              tagData={this.state.tagData}
              tagReadingsData={this.state.tagReadingsData}
              showRawFields={this.state.showRawFields}
              onToggleRawFields={this.toggleShowRawFields}
              showTagHistory={this.state.showTagHistory}
              tagHistory={this.state.tagHistory}
              onToggleHistory={this.toggleShowTagHistory}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SAMconnect(null, null)(TagViewer);
