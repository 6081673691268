import React, { Component } from 'react';
import { getLocalDate } from './RequestDetails';

export class RequestInteropDetails extends Component {
  renderInterop = i => (
    <fieldset key={i.id}>
      <div>
        <b>{i.customerinterop_description}</b> ({i.customerinterop_tei},
        CustomerInteropId : {i.CustomerInteropId})
        <br />
        type : {i.customerinterop_type}
        <fieldset>
          value : <b>{i.value}</b>
          <br />
          id : {i.id}
          <br />
          UTC date : {getLocalDate(i).utc}
          <br />
          French date : {getLocalDate(i).local}
          <br />
          Created with time offset {i.timeOffset}
          <br />
          nonce : {i.nonce}
          <br />
          Created by user {i.UserId} ({i.user_login}, {i.user_name})
        </fieldset>
        {i.ReportId && (
          <fieldset>
            <legend>Linked report</legend>
            id : {i.ReportId}
            <br />
            name : {i.report_name}
            <br />
            date : {i.report_date}{' '}
            <b style={{ color: 'orange' }}>
              (Displayed date can be invalid as it does not take timeoffset into
              account. Please check database for exact value.)
            </b>
            <br />
            nonce : {i.report_nonce}
            <br />
            Created by user {i.report_userid}
            <br />
            Data : <br />
            <div
              style={{
                border: '1px solid lightgray',
                maxHeight: 400,
                overflow: 'auto',
              }}
            >
              <ul>
                {i.report_data &&
                  JSON.parse(i.report_data)
                    .filter(reportField => reportField.type !== 'section')
                    .map(reportField => (
                      <li>
                        {reportField.title} : {reportField.value}
                      </li>
                    ))}
              </ul>
            </div>
          </fieldset>
        )}
      </div>
    </fieldset>
  );

  render() {
    if (
      !this.props.requestInteropData ||
      this.props.requestInteropData.length === 0
    )
      return <div>No interop</div>;

    return (
      <div>
        {this.props.requestInteropData.map(interop =>
          this.renderInterop(interop),
        )}
      </div>
    );
  }
}
