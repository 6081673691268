import React, { Component } from 'react';
import * as network from '../config/network';
import { SAMconnect } from '../lib/SAMconnect';
import { get } from '../lib/api';
import { RequestDetails } from '../components/request-viewer/RequestDetails';
import { RequestInteropDetails } from '../components/request-viewer/RequestInteropDetails';

class RequestViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: 138000,
      requestData: null,
      requestInteropData: null,
      showRawFields: false,
      errorMessage: null,
    };
  }

  handleRequestIdChange = event => {
    this.setState({ requestId: event.target.value });
  };

  toggleShowRawFields = event => {
    this.setState(previousState => ({
      showRawFields: !previousState.showRawFields,
    }));
  };

  changeRequest = (forward = true) => {
    this.setState(
      previousState => ({
        requestId: parseInt(
          parseInt(previousState.requestId, 10) + (forward ? 1 : -1),
          10,
        ),
      }),
      () => this.fetchRequest(this.state.requestId),
    );
  };

  previousRequest = () => this.changeRequest(false);
  nextRequest = () => this.changeRequest(true);

  handleSubmit = event => {
    this.fetchRequest(this.state.requestId);
    event.preventDefault();
  };

  fetchRequest = requestId => {
    this.setState(
      {
        requestData: null,
        requestInteropData: null,
      },
      () => {
        get(
          this.props.api,
          `${network.API_REQUEST_VIEWER}?requestId=${requestId}`,
          this.props.token,
        )
          .then(requestData => {
            if (!requestData) {
              this.setState({
                errorMessage: `Could not load request, maybe it doesn't exist (using API ${
                  this.props.api
                })`,
              });
            } else {
              this.setState({ requestData, errorMessage: null });
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({ errorMessage: err.message });
          });
        get(
          this.props.api,
          `${network.API_REQUEST_VIEWER}/interop?requestId=${requestId}`,
          this.props.token,
        )
          .then(requestInteropData => {
            console.log(requestInteropData);
            this.setState({ requestInteropData });
          })
          .catch(err => {
            console.log(err);
            this.setState({ errorMessage: err.message });
          });
      },
    );
  };

  renderResults = () => {
    return (
      this.state.requestData && (
        <div>
          <RequestDetails
            requestData={this.state.requestData}
            showRawFields={this.state.showRawFields}
            onToggleRawFields={this.toggleShowRawFields}
          />
          <h4>RequestInterops</h4>
          {this.state.requestInteropData && (
            <RequestInteropDetails
              requestInteropData={this.state.requestInteropData}
            />
          )}
        </div>
      )
    );
  };

  render() {
    return (
      <div className="request-viewer">
        <h1>Request viewer</h1>
        <div className="container">
          <form
            className="request-selector"
            onSubmit={this.handleSubmit}
            id="requestForm"
          >
            <legend>Enter a RequestId:</legend>
            <div className="input-bar">
              <input
                type="button"
                onClick={this.previousRequest}
                value="Previous"
              />
              <input
                type="text"
                name="requestId"
                value={this.state.requestId}
                onChange={this.handleRequestIdChange}
              />
              <input type="button" onClick={this.nextRequest} value="Next" />
            </div>
            <div className="fetch-bar">
              <input type="submit" onClick={this.handleSubmit} value="Fetch" />
            </div>
          </form>
          {this.state.errorMessage}
          {this.renderResults()}
        </div>
      </div>
    );
  }
}

export default SAMconnect(null, null)(RequestViewer);
