import * as types from '../config/types';

export const setAPIToken = token => ({
  type: types.API_SET_TOKEN,
  token,
});

export const setAPI = api => ({
  type: types.API_SET_API,
  api,
});
