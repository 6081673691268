import React, { Component } from 'react';
import {
  Grid,
  Segment,
  Label,
  Button,
  Loader,
  Divider,
  Message,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { SAMconnect } from '../../lib/SAMconnect';
import { getMeshDetails } from '../../selectors/mesh';
import { getLayerColor } from '../../lib/utils';
import Babylon from '../../components/mesh/babylon';
import List from '../../components/mesh/list';

class MeshDetail extends Component {
  constructor(props) {
    super(props);

    this.selectLayer = this.selectLayer.bind(this);

    this.state = {
      selectedLayer: null,
    };
  }

  componentWillMount() {
    this.props.actions.selectMesh(this.props.match.params.meshId);
  }

  selectLayer(selectedLayer) {
    this.setState({ selectedLayer });
  }

  buildCurrent = async () => {
    this.props.actions.showBuildModal();
    const config = this.props.mesh.find(
      metadata => metadata.layer === this.state.selectedLayer,
    );
    this.props.actions.setBuildProgress(
      `Rep ${config.equipmentRepId} on layer ${config.layer}`,
      0,
    );
    const mesh = await this.props.babylon.current.buildSceneAsync(config);
    this.props.actions.setBuildProgress('Sending to API...', 50);

    this.props.actions.sendMeshes([mesh], this.props.token).then(() => {
      this.props.actions.setBuildProgress('Success!', 100);
    });
  };

  buildAll = async () => {
    this.props.actions.showBuildModal();
    const meshes = [];
    let promise = Promise.resolve();
    let currentStep = 0;
    const stepCount = this.props.mesh.length + 1;
    this.props.actions.setBuildProgress(
      `Rep${this.props.mesh[0].equipmentRepId} on layer ${
        this.props.mesh[0].layer
      }`,
      0,
    );
    this.props.mesh.map(config => {
      promise = promise.then(result => {
        if (result) meshes.push(result);
        currentStep += 1;
        this.props.actions.setBuildProgress(
          `Rep ${config.equipmentRepId} on layer ${config.layer}`,
          Math.round(currentStep / stepCount * 100),
        );
        return this.props.babylon.current.buildSceneAsync(config);
      });
      return null;
    });
    await promise.then(result => {
      if (result) meshes.push(result);
      currentStep += 1;
      this.props.actions.setBuildProgress(
        'Sending to API...',
        Math.round(currentStep / stepCount * 100),
      );
      return null;
    });
    this.props.actions.sendMeshes(meshes, this.props.token).then(() => {
      this.props.actions.setBuildProgress('Success!', 100);
    });
  };

  renderLayerLabel() {
    if (!this.state.selectedLayer) return null;
    const layerConfig = this.props.layers.find(
      layer => layer.id === parseInt(this.state.selectedLayer, 10),
    );
    return (
      <Label color={getLayerColor(layerConfig.id.toString())}>
        {layerConfig.description}
      </Label>
    );
  }

  renderLayerButton(metadata) {
    const layerConfig = this.props.layers[parseInt(metadata.layer, 10) - 1];
    return (
      <Button
        key={`layer${metadata.layer}`}
        color={getLayerColor(metadata.layer)}
        onClick={() => this.selectLayer(metadata.layer)}
      >
        {layerConfig.description}
      </Button>
    );
  }

  renderActionBar() {
    const config = this.props.mesh.find(
      metadata => metadata.layer === this.state.selectedLayer,
    );
    return (
      <Segment>
        {this.renderLayerLabel()}
        <strong>{`   ${config.models.length} models in this layer`}</strong>
        <Button
          floated="right"
          size="mini"
          key="buildLayer"
          onClick={() => this.buildCurrent()}
        >
          Build Layer
        </Button>
      </Segment>
    );
  }

  render3dView() {
    if (!this.state.selectedLayer) return null;
    const config = this.props.mesh.find(
      metadata => metadata.layer === this.state.selectedLayer,
    );
    return (
      <Segment>
        <Grid divided columns="2">
          <Grid.Column width="10">
            <Babylon config={config} />
            <br />
            <strong>left click + drag :</strong> rotate
            <br />
            <strong>right click + drag :</strong> pan
          </Grid.Column>
          <Grid.Column id="meshList" width="6">
            <List config={config} />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  renderDetail() {
    const layerLabels = this.props.mesh.map(layer =>
      this.renderLayerButton(layer),
    );
    return (
      <div>
        <Segment.Group>
          <Segment>
            {layerLabels}
            <Button
              floated="right"
              key="buildAllLayers"
              primary
              onClick={() => this.buildAll()}
            >
              Build All Layers
            </Button>
          </Segment>
          {this.state.selectedLayer ? this.renderActionBar() : null}
        </Segment.Group>
        {this.render3dView()}
      </div>
    );
  }

  render() {
    let content = null;
    if (!this.props.mesh || typeof this.props.mesh === 'boolean')
      content = <Loader active size="large" />;
    else content = this.renderDetail();
    return (
      <div>
        <h1>Mesh {this.props.match.params.meshId} detail</h1>
        <Message warning>
          <Message.Header>
            Please launch the mesh server to use this page
          </Message.Header>
          <p>
            <code>yarn server</code> in repo root folder
          </p>
        </Message>
        <Divider />
        <div>{content}</div>
      </div>
    );
  }
}

MeshDetail.propTypes = {
  mesh: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  layers: PropTypes.array,
  babylon: PropTypes.object,
};
const mapStateToProps = state => ({
  mesh: getMeshDetails(state),
  layers: state.mesh.layers,
});

export default SAMconnect(mapStateToProps, null)(MeshDetail);
