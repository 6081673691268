import React, { Component } from 'react';
import { Icon, Label, Button, Menu } from 'semantic-ui-react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes, { instanceOf } from 'prop-types';

import { get } from '../lib/api';
import * as network from '../config/network';

class AISMenu extends Component {
  constructor(props) {
    super(props);
    this.checkActive = this.checkActive.bind(this);
    this.navigate = this.navigate.bind(this);
    this.requestNewToken = this.requestNewToken.bind(this);
    const { cookies } = this.props;
    this.state = {
      activeMenu:
        props.location.pathname.split('/').filter(x => x)[0] || 'home',
      tokenExpires: Math.round(
        (cookies.get('tokenExpires') - Date.now()) / 60000,
      ),
      status: {
        color: 'orange',
        message: 'checking...',
      },
    };
  }

  componentWillMount() {
    get(this.props.api, network.API_STATUS, null, true)
      .then(response => {
        if (response.message === 'API OK') {
          this.setState({
            status: {
              color: 'green',
              message: 'OK',
            },
          });
        } else {
          this.setState({
            status: {
              color: 'red',
              message: 'ERROR',
            },
          });
        }
      })
      .catch(() => {
        this.setState({
          status: {
            color: 'red',
            message: 'ERROR',
          },
        });
      });
  }

  checkActive(name) {
    if (this.state.activeMenu === name) return true;
    return false;
  }

  navigate(activeMenu, path) {
    this.setState({ activeMenu });
    this.props.navigate(path);
  }

  requestNewToken() {
    const { cookies } = this.props;
    cookies.remove('token');
    cookies.remove('tokenExpires');
    window.location.reload();
  }

  render() {
    return (
      <Menu>
        <Menu.Item
          header
          name="home"
          active={this.checkActive('home')}
          onClick={() => this.navigate('home', '/')}
        >
          AIS BackOffice
        </Menu.Item>
        <Menu.Item
          name="cache"
          active={this.checkActive('cache')}
          onClick={() => this.navigate('cache', '/cache')}
        >
          Cache
        </Menu.Item>
        <Menu.Item
          name="mesh"
          active={this.checkActive('mesh')}
          onClick={() => this.navigate('mesh', '/mesh')}
        >
          Mesh
        </Menu.Item>
        <Menu.Item
          name="sandbox"
          active={this.checkActive('sandbox')}
          onClick={() => this.navigate('sandbox', '/sandbox')}
        >
          Sandbox
        </Menu.Item>
        <Menu.Item
          name="archive"
          active={this.checkActive('archive')}
          onClick={() => this.navigate('archive', '/archive')}
        >
          Archives
        </Menu.Item>
        <Menu.Item
          name="manual"
          active={this.checkActive('manual')}
          onClick={() => this.navigate('manual', '/manual')}
        >
          User Manuals
        </Menu.Item>
        <Menu.Item
          name="dataConsistency"
          active={this.checkActive('dataConsistency')}
          onClick={() => this.navigate('dataConsistency', '/data-consistency')}
        >
          Data Consistency
        </Menu.Item>
        <Menu.Item
          name="userAnalytics"
          active={this.checkActive('userAnalytics')}
          onClick={() => this.navigate('userAnalytics', '/user-analytics')}
        >
          User Analytics
        </Menu.Item>
        <Menu.Item
          name="requestViewer"
          active={this.checkActive('requestViewer')}
          onClick={() => this.navigate('requestViewer', '/request-viewer')}
        >
          Request Viewer
        </Menu.Item>
        <Menu.Item
          name="tagViewer"
          active={this.checkActive('tagViewer')}
          onClick={() => this.navigate('tagViewer', '/tag-viewer')}
        >
          Tag Viewer
        </Menu.Item>
        <Menu.Item
          name="rsaToken"
          active={this.checkActive('rsaToken')}
          onClick={() => this.navigate('rsaToken', '/rsa-token')}
        >
          RSA token
        </Menu.Item>
        <Menu.Item position="right">
          <Button
            as="div"
            labelPosition="right"
            onClick={() => this.requestNewToken()}
          >
            <Button color="purple" icon>
              New token
            </Button>
            <Label basic>
              <Icon name="time" />
              {`${this.state.tokenExpires}m`}
            </Label>
          </Button>
          <Label color={this.state.status.color}>
            API
            <Label.Detail>{this.state.status.message}</Label.Detail>
          </Label>
        </Menu.Item>
      </Menu>
    );
  }
}

AISMenu.propTypes = {
  location: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  api: PropTypes.string,
};

const mapStateToProps = state => ({
  api: state.API.api,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigate: path => push(path),
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(AISMenu)));
