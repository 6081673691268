import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Table } from 'semantic-ui-react';

class MeshList extends Component {
  renderItem(model, index) {
    return (
      <List.Item key={index}>
        <List.Content>
          <List.Header>{model.file}</List.Header>
          <List.Description>
            <Table basic="very" compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell collapsing>X</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Y</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Z</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Position</Table.Cell>
                  <Table.Cell>{model.position.x}</Table.Cell>
                  <Table.Cell>{model.position.y}</Table.Cell>
                  <Table.Cell>{model.position.z}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Rotation</Table.Cell>
                  <Table.Cell>{model.rotation.x}</Table.Cell>
                  <Table.Cell>{model.rotation.y}</Table.Cell>
                  <Table.Cell>{model.rotation.z}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
  render() {
    const items = this.props.config.models.map((model, index) =>
      this.renderItem(model, index),
    );
    return (
      <List divided relaxed>
        {items}
      </List>
    );
  }
}

MeshList.propTypes = {
  config: PropTypes.object,
};

export default MeshList;
