import React from 'react';
import { Image } from 'semantic-ui-react';

const Home = props => (
  <div>
    <h3>Hello there!</h3>
    <p>
      <Image
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo7Q9hLtMLsRT9oxN5p7l5N2FeCY_owHyUtE3nTSm2nUyvdd57ZuXzvHDmX7wWNheo068&usqp=CAU"
      />
    </p>
  </div>
);

export default Home;
