import React, { Component } from 'react';
import { Button, Form, Segment, Message } from 'semantic-ui-react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import actions from '../actions';
import * as network from '../config/network';
import { post } from '../lib/api';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      error: false,
      static: true,
      servers: null,
      selectedServer: 'local',
    };
  }

  componentDidMount() {
    fetch('./servers.json')
      .then(res => res.json())
      .then(servers => {
        this.setState({ servers, static: false });
        return null;
      })
      .catch(() => {
        console.warn('Could not find server list, switching to static config');
      });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleSubmit = async () => {
    const { login, password } = this.state;
    var form = new FormData();
    form.append('login', login);
    form.append('password', password);
    const api = this.state.static
      ? network.API_URL
      : this.state.servers[this.state.selectedServer];
    const response = await post(
      api,
      network.API_TOKEN,
      {
        login,
        password,
        udid: 'aisbackoffice',
        deviceName: 'aisbackoffice',
        backOfficeToken: network.API_BACKOFFICE_TOKEN,
        userAgent: `${navigator.appCodeName}-${navigator.appName}-${
          navigator.platform
        }`,
      },
      null,
      true,
    );
    if (response.token) {
      const { cookies } = this.props;
      const expireTimestamp = new Date();
      expireTimestamp.setSeconds(
        expireTimestamp.getSeconds() + network.API_TOKEN_TTL,
      );
      cookies.set('token', response.token, {
        path: '/',
        expires: expireTimestamp,
      });
      cookies.set('tokenExpires', expireTimestamp.getTime(), {
        path: '/',
        expires: expireTimestamp,
      });
      cookies.set('server', this.state.servers[this.state.selectedServer], {
        path: '/',
        expires: expireTimestamp,
      });
      this.props.actions.setAPI(api);
      this.props.actions.setAPIToken(response.token);
    } else {
      this.setState({ error: true });
    }
  };

  selectServer = selectedServer => {
    this.setState({ selectedServer });
  };

  render() {
    const { login, password } = this.state;
    const servers = this.state.static
      ? null
      : Object.keys(this.state.servers).map(server => (
          <Form.Radio
            label={`${server} : ${this.state.servers[server]}`}
            value={this.state.servers[server]}
            key={server}
            checked={this.state.selectedServer === server}
            onClick={() => this.selectServer(server)}
          />
        ));
    const serverChoice = this.state.static ? null : (
      <div>
        <h4>Server</h4>
        <Form.Group grouped>{servers}</Form.Group>
      </div>
    );
    return (
      <div>
        <h1>Please login for API token</h1>
        <Segment inverted>
          <Form error={this.state.error} inverted onSubmit={this.handleSubmit}>
            {serverChoice}
            <Form.Input
              label="Login"
              name="login"
              placeholder="login"
              value={login}
              onChange={this.handleChange}
            />
            <Form.Input
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={this.handleChange}
            />
            <Button type="submit">Submit</Button>
            <Message error header="Niet." content="You cheeki breeki." />
          </Form>
        </Segment>
      </div>
    );
  }
}

Login.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(withCookies(Login));
