import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';

class dataConsistency extends Component {
  constructor(props) {
    super(props);
    this.loadDatabase = this.loadDatabase.bind(this);
    this.loadAbnormalities = this.loadAbnormalities.bind(this);
    this.renderAbnomalities = this.renderAbnomalities.bind(this);
    this.dictionnaryToTable = this.dictionnaryToTable.bind(this);
    this.prefillDatabase = this.prefillDatabase.bind(this);
    this.renderDbInput = this.renderDbInput.bind(this);
    this.state = {
      databases: [],
      databaseParams: {
        mysqlHostname: '',
        mysqlUser: '',
        mysqlPassword: '',
        mysqlDatabase: '',
      },
      customerId: '',
      abnormalities: {},
    };
    this.loadDatabase();
  }

  loadDatabase = () => {
    return fetch('http://localhost:27669/data-consistency/databases', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          databases: response,
        });
        if (response[0]) {
          this.setState({
            databaseParams: {
              mysqlHostname: response[0].mysql_host,
              mysqlUser: response[0].mysql_user,
              mysqlPassword: response[0].mysql_password,
              mysqlDatabase: response[0].mysql_database,
            },
          });
        }
      })
      .catch(error => console.error(`Fetch Error =\n`, error));
  };

  loadAbnormalities = () => {
    return fetch(
      `http://localhost:27669/data-consistency/checkup?customerId=${
        this.state.customerId
      }&mysql_host=${this.state.databaseParams.mysqlHostname}&mysql_user=${
        this.state.databaseParams.mysqlUser
      }&mysql_password=${
        this.state.databaseParams.mysqlPassword
      }&mysql_database=${this.state.databaseParams.mysqlDatabase}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          throw response.error;
        }
        this.setState({ abnormalities: response });
      })
      .catch(error => alert(error));
  };

  dictionnaryToTable = array => {
    return array.map((data, key) => {
      const tableBody = Object.keys(data).map(key => {
        return (
          <tr key={key} style={{ backgroundColor: '#ddd' }}>
            <td style={{ padding: 7 }}>
              <b>{key}</b>
            </td>
            <td style={{ padding: 7 }}>{data[key]}</td>
          </tr>
        );
      });
      return (
        <table key={key} style={{ marginTop: 15 }}>
          <tbody>{tableBody}</tbody>
        </table>
      );
    });
  };

  prefillDatabase = e => {
    const index = e.target.selectedIndex;
    const database = this.state.databases[index];
    this.setState({
      databaseParams: {
        mysqlHostname: database.mysql_host,
        mysqlUser: database.mysql_user,
        mysqlPassword: database.mysql_password,
        mysqlDatabase: database.mysql_database,
      },
    });
  };

  renderAbnomalities = () => {
    const abnormalities = this.state.abnormalities;
    let output;
    if (abnormalities) {
      output = Object.keys(abnormalities).map((key, index) => {
        var value = abnormalities[key];
        return (
          <div key={index}>
            <h2 style={{ marginTop: 50 }}>
              {value.details} ({value.length}):
            </h2>
            {this.dictionnaryToTable(value.data)}
          </div>
        );
      });
    }
    return <div>{output}</div>;
  };

  renderSelectDatabases = () => {
    let options;
    if (this.state.databases) {
      options = this.state.databases.map((key, index) => {
        return (
          <option id={index}>
            {key.mysql_user}@{key.mysql_host}
          </option>
        );
      });
    }
    return <select onChange={this.prefillDatabase}>{options}</select>;
  };

  renderDbInput(placeholder, type, value) {
    return (
      <p>
        <input
          id={value}
          placeholder={placeholder}
          type={type}
          style={{ padding: 5 }}
          value={this.state.databaseParams[value]}
          onChange={event =>
            this.setState({
              databaseParams: {
                ...this.state.databaseParams,
                [value]: event.target.value,
              },
            })
          }
        />
      </p>
    );
  }

  render() {
    return (
      <div>
        <h3>Data Consistency Checkup</h3>
        <h4>Database settings</h4>
        <p>{this.renderSelectDatabases()}</p>
        {this.renderDbInput('MySQL hostname', 'text', 'mysqlHostname')}
        {this.renderDbInput('MySQL user', 'text', 'mysqlUser')}
        {this.renderDbInput('MySQL password', 'password', 'mysqlPassword')}
        {this.renderDbInput('MySQL database', 'text', 'mysqlDatabase')}
        <h4>Customer Settings</h4>
        <p>
          <input
            id="customerId"
            placeholder="Customer Id"
            type="text"
            value={this.state.customerId}
            onChange={event =>
              this.setState({
                customerId: event.target.value,
              })
            }
            style={{ padding: 5 }}
          />
        </p>
        <p>
          <input
            type="button"
            onClick={this.loadAbnormalities}
            value="get database abnormalities"
          />
        </p>
        {this.renderAbnomalities()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  api: state.API.api,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigate: path => push(path),
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(dataConsistency)));
