import React from 'react';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMonokai from 'react-json-pretty/dist/monikai';
import { TagReadingDetails } from './TagReadingDetails';
import { TagHistory } from './TagHistory';
const { decodeAFEPC, decodeATAEPC } = require('@ais/rfid-hexa-reader');

const decodeEpc = epc => {
  try {
    if (epc.toLowerCase().startsWith('3b')) {
      return decodeATAEPC(epc);
    }
    return decodeAFEPC(epc);
  } catch (error) {
    return null;
  }
};

export const TagDetails = props => {
  if (!props.tagData) return <div>No data</div>;
  const t = props.tagData;
  const tr = props.tagReadingsData;
  let decodedEpc = {};
  if (t && t.epc && t.epc !== 'NC') {
    try {
      decodedEpc = decodeEpc(t.epc);
    } catch (err) {
      console.warn(err);
      decodedEpc = { error: `Could not decode EPC : ${err.message}` };
    }
  }
  return (
    <div>
      <br />
      <h4>
        Tag {t.id} - {t.description}
      </h4>
      <input
        type="button"
        onClick={props.onToggleRawFields}
        value={props.showRawFields ? 'Hide raw fields' : 'show raw fields'}
      />
      {props.showRawFields ? (
        <fieldset>
          <ul>
            {Object.keys(t).map(field => (
              <li>
                {field} : {t[field]}
              </li>
            ))}
          </ul>
        </fieldset>
      ) : null}
      <input
        type="button"
        onClick={props.onToggleHistory}
        value={props.showTagHistory ? 'Hide tag history' : 'show tag history'}
      />
      {props.showTagHistory ? (
        <TagHistory history={props.tagHistory} epc={t.epc} />
      ) : null}
      <br />

      {t.epc && t.epc !== 'NC' ? (
        <fieldset>
          <legend>EPC Data</legend>
          <div>
            Raw EPC : <b>{t.epc}</b>
            <br />
            Tag type :{' '}
            {t.epc.toLowerCase().startsWith('3b')
              ? 'ATA spec 2000'
              : 'AirFrance'}
            <br />
            <span>{'Decoded EPC :'}</span>
            <JSONPretty
              id="pretty-epc-data"
              theme={JSONPrettyMonokai}
              data={decodedEpc}
            />
          </div>
        </fieldset>
      ) : (
        <b>No tag currently assigned to this position.</b>
      )}
      <br />
      {tr && tr.length ? (
        <div>
          <fieldset>
            <legend>User memory - latest reading</legend>
            <TagReadingDetails
              readingData={tr[0]}
              expectedSer={decodedEpc.serialNumber}
              expectedEpc={t.epc}
            />
          </fieldset>
          <br />
          <br />
          {tr.length > 1 && (
            <fieldset>
              <legend>User memory - previous readings</legend>
              {tr.slice(1).map(reading => (
                <fieldset key={reading.id}>
                  <legend>{reading.date}</legend>
                  <TagReadingDetails
                    readingData={reading}
                    expectedSer={decodedEpc.serialNumber}
                  />
                </fieldset>
              ))}
            </fieldset>
          )}
        </div>
      ) : (
        <b>{'No memory reading.'}</b>
      )}
    </div>
  );
};
