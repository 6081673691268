import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { SAMconnect } from '../lib/SAMconnect';
import Menu from './menu';
import Login from './login';
import Cache from './cache';
import Mesh from './mesh';
import Home from './home';
import Sandbox from './sandbox';
import Archive from './archive';
import Manual from './manual';
import DataConsistency from './dataConsistency';
import userAnalytics from './userAnalytics';
import RequestViewer from './RequestViewer';
import TagViewer from './TagViewer';
import RSATokenGenerator from './RSATokenGenerator';

import MeshModal from '../components/mesh/modal';

class App extends Component {
  renderLogin() {
    return (
      <div>
        <Container text>
          <Login />
        </Container>
      </div>
    );
  }

  renderMain() {
    return (
      <div>
        <Menu />
        <Container>
          <main>
            <Route exact path="/" component={Home} />
            <Route path="/cache" component={Cache} />
            <Route path="/mesh" component={Mesh} />
            <Route path="/sandbox" component={Sandbox} />
            <Route path="/request-viewer" component={RequestViewer} />
            <Route path="/tag-viewer" component={TagViewer} />
            <Route path="/rsa-token" component={RSATokenGenerator} />
            <Route path="/archive" component={Archive} />
            <Route path="/manual" component={Manual} />
            <Route path="/data-consistency" component={DataConsistency} />
            <Route path="/user-analytics" component={userAnalytics} />
          </main>
        </Container>
        <MeshModal
          show={this.props.showBuildModal}
          percent={this.props.buildModalProgress}
          stepLabel={this.props.buildModalStepLabel}
          hideModal={this.props.actions.hideBuildModal}
        />
      </div>
    );
  }

  render() {
    return this.props.token ? this.renderMain() : this.renderLogin();
  }
}

App.propTypes = {
  path: PropTypes.string,
  showBuildModal: PropTypes.bool,
};
const mapStateToProps = state => ({
  path: state.routing.location.pathname,
  showBuildModal: state.mesh.showBuildModal,
  buildModalProgress: state.mesh.buildModalProgress,
  buildModalStepLabel: state.mesh.buildModalStepLabel,
});

export default SAMconnect(mapStateToProps, null)(App);
